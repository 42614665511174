import { amgApi } from "@/service/axios";

class EntrancesService {
    
    async getAllEntrancesType() {
        const data = await amgApi.post('/business/settings/get-all-entrances-type');
        return data;
    }
    
    async getAllEntrancesTypeSubCharges(body) {
        const data = await amgApi.post('/business/settings/get-subcharges-list', body);
        return data;
    }
    
    async getFeeLossBySubCharge(body) {
        const data = await amgApi.post('/business/settings/get-feeloss-by-subcharge', body);
        return data;
    }
    
    async createNewEntrance(body) { 
        const res = await amgApi.post('/business/settings/create-new-entrance', body);
        return res;
    }
    
    async deleteEntrance(body) {
        const res = await amgApi.post('/business/settings/delete-entrance', body);
        return res;
    }
    
    async getAllEntrances(body) {
        const res = await amgApi.post('/business/settings/show-all-entrances', body);
        return res;
    }
    
    async updateEntrance(body) {
        const res = await amgApi.post('/business/settings/update-entrance', body);
        return res;
    }
    
    async getAllTrackingEntries(body) {
        const res = await amgApi.post('/business/settings/get-all-tracking-entries', body);
        return res;
    }

    async deleteSubCharge(body) {
        const data = await amgApi.post('/business/settings/delete-subcharge', body);
        return data;
    }
}

export default new EntrancesService();