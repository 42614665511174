export default [
  {
    label: "TYPE",
    key: "type_of_entrance_name",
    visible: true,
    tdClass: "tdClass",
  },
  {
    label: "DESCRIPTION",
    key: "description",
    visible: true,
    tdClass: "tdClass",
  },
  {
    label: "CREATED AT",
    key: "created_at",
    visible: true,
    tdClass: "tdClass",
  },
  {
    label: "CREATED BY",
    key: "created_by",
    visible: true,
    tdClass: "tdClass",
  },
  {
    label: "TRACKING",
    key: "tracking",
    visible: true,
    tdClass: "tdClass",
  },
  {
    key: "action",
    label: "ACTIONS",
    visible: true,
    tdClass: "text-center",
    thClass: "text-center",
  },
];
