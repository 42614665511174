<template>
  <div class="px-2">

    <b-tabs
					active-tab-class="p-0 "
					pills
					nav-class="mb-0 mt-1"
					active-nav-item-class="bg-info box-shadow-info border-info info"
    >
					<b-tab 
          lazy 
          title="Active" 
          :title-link-class="[bgTabsNavs, 'sub-tab px-3']"
          @click=" getByChargeStatus(0) "
          ></b-tab>
					<b-tab 
          lazy 
          title="Inactive" 
          :title-link-class="[bgTabsNavs, 'sub-tab px-3']"
          @click=" getByChargeStatus(1) "
          ></b-tab>
    </b-tabs>


    <filter-slot
      :filter="filters"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      :total-rows="totalRows"
      :filter-principal="filterPrincipal"
      @reload="$refs['entrancesListTable'].refresh()"
    >
      <template #table>
        <b-table
          small
          slot="table"
          no-provider-filtering
          ref="entrancesListTable"
          :items="myProvider"
          :fields="fields"
          primary-key="id"
          table-class="text-nowrap"
          responsive="sm"
          show-empty
          sticky-header="70vh"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
        >
          <template v-slot:cell(created_at)="data">
              {{ data.item.created_at | myGlobalWithHour }}
          </template>
          <template v-slot:cell(tracking)="data">
            <feather-icon
              icon="ListIcon"
              title="INFO"
              size="20"
              class="cursor-pointer m-1 text-primary"
              @click="openTrackingModal(data.item.id)"
            />
          </template>
          <template v-slot:cell(action)="data">
            <feather-icon
              icon="EditIcon"
              title="EDIT"
              size="20"
              class="cursor-pointer m-1 text-primary"
              @click="openCreateAndEditModalEntrances(data.item)"
            />
            <feather-icon
              v-if="isDeleted == 0"
              icon="TrashIcon"
              title="DELETE"
              size="20"
              class="cursor-pointer m-1 text-danger"
              @click="deleteEntrance(data.item)"
            />
          </template>
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
        </b-table>
      </template>
    </filter-slot>
    <modal-tracking
      v-if="trackingModalOpened"
      :items="tracking_list"
      @close="closeTrackingModal"
    />
    <modal-create-and-edit-entrances
      v-if="createAndEditModalEntrancesOpened"
      :operationType="editOperation"
      :entrance="currentEntrance"
      @close="closeCreateAndEditModalEntrances"
      @update="updateEntrancesTable"
    />
    
  </div>
</template>

<script>
import Fields from "../data/fields.settingsEntrances";
import Filters from "../data/filters.settingsEntrances";
import EntrancesService from "../service/entrancesService";
import { mapGetters } from "vuex";

export default {
  components: {
      ModalTracking: () => import(/* webpackChunkName: "Business/Settings: ModalTracking" */ "./ModalTracking.vue"),
      ModalCreateAndEditEntrances: () => import(/* webpackChunkName: "Business/Settings: CreateEditModal" */ "./ModalCreateAndEditEntrances.vue"),
  },
  props: {
    isDeleted: {
      type: Number,
      required: true,
    },
    EntranceType: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      /*opciones: [
          {text:'demo',value:1},
          {text:'demo2',value:2}
      ],
      modaldemo:true,
      selecteds:[],
      listTypeEntrance:[1],*/
      
      
      createAndEditModalEntrancesOpened: false,

      currentEntrance: {},
      editOperation: 2,
      fields: Fields,
      filters: Filters,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Entrance...",
        model: "",
      },
      paginate: {
        currentPage: 1,
        perPage: 100,
      },
      startPage: null,
      trackingModalOpened: false,
      tracking_list: [],
      totalRows: 0,
      toPage: null,
    }
  },
  methods: {
    updateEntrancesTable() {
      this.$refs['entrancesListTable'].refresh();
    },
    async deleteEntrance(item) {
      const swalValidation = await this.showConfirmSwal();
      if(swalValidation.isConfirmed) {
        try {
          await EntrancesService.deleteEntrance({
            entrance_id: item.id,
            user_id: this.currentUser.user_id,
            type_of_entrance: item.type_of_entrance_id
          });
          this.updateEntrancesTable();
        } catch (err) {
          console.error('Error trying to delete entrance');
          throw err;
        }
      }
    },
    async openTrackingModal(id) {
      this.trackingModalOpened = true;
      try {
        const res =  await EntrancesService.getAllTrackingEntries({
                        entrance_id: id
                     });
        this.tracking_list = res.data;
      } catch (err) {
        console.error('Error trying to get tracking entries');
        throw err;
      }
    },
    openCreateAndEditModalEntrances(currentEntrance) {
      this.currentEntrance = currentEntrance;
      this.createAndEditModalEntrancesOpened = true;
    },
    closeTrackingModal() {
      this.trackingModalOpened = false;
    },
    closeCreateAndEditModalEntrances() {
      this.createAndEditModalEntrancesOpened = false;
    },
    async myProvider(ctx) {
      try {
        const params = {
          isDeleted: this.isDeleted,
          entrance_type: this.EntranceType,
          from: this.filters[0].model,
          to: this.filters[1].model,
          description: this.filterPrincipal.model,
          page: this.paginate.currentPage,
          perpage: this.paginate.perPage,
        }
        const { data } = await EntrancesService.getAllEntrances(params);
        this.totalRows = data.total;
        this.startPage = data.from;
        this.toPage = data.to;
        this.paginate.currentPage = data.current_page;
        this.paginate.perPage = data.per_page;
        return data.data;
      } catch (err) {
        console.error('Error trying to get all the entrances');
        throw err;
      }
    },
    addTime(){
      this.listTypeEntrance.push(2);
    },
    getByChargeStatus(status){
        this.isDeleted=status;
        this.updateEntrancesTable();
    }
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      G_UPDATE_TABLE: "BusinessSettings/G_UPDATE_TABLE"
    }),
  },
  watch: {
    G_UPDATE_TABLE(currentState) {
      if(currentState){
        this.updateEntrancesTable()
      }
    }
  }
}
</script>